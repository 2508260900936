import React from 'react'
import Post from '../Post'

import './style.scss'

class CategoryTemplateDetails extends React.Component {
  render() {
  const items = []
  const { category } = this.props.pageContext
  const posts = this.props.data.allMarkdownRemark.edges
  posts.forEach(post => {
    items.push(<Post data={post} key={post.node.fields.slug} />)
  })
  
      return (
          <div className="content">
              <div className="content__inner">
                  <div className="page">
                  
                      <h1 className="page__title" id="category_title_area">
                          <div className="category_title">
                              <span className="categoryMark"></span>
                              &quot;
                              {category}
                              &quot;
                          </div>
                          <div className="number_of_post_categorypage">{items.length}件の記事があります</div>
                      </h1>
                      <div className="page__body">{items}</div>
                  </div>
              </div>
          </div>
      )
  }
}

export default CategoryTemplateDetails